body {
  font-family: 'Arial', sans-serif;
  
  /* background-color: #17152f; */
  /* background: linear-gradient(220.55deg, #2b4255 0%, #0E2C5E 100%);  */
  /* background: linear-gradient(to right, #000428, #082742); */
  
  background: linear-gradient(to bottom right, #111827, #2b4255 0%,  #000428);

  
  color: rgba(255, 255, 255, 0.8);
}

.session-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.session-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.session-card-body {
  padding: 0;
}

.dropdown-toggle-custom {
  /* backgroundColor: 'rgba(255, 255, 255, 0.1)', // Yarı saydam arka plan
  backdropFilter: 'blur(10px)', // Arka planı bulanıklaştır
  WebkitBackdropFilter: 'blur(10px)', // Safari için destek
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', // Hafif gölge
  border: '1px solid rgba(255, 255, 255, 0.2)', */
/* 
  background-color: rgba(110, 106, 106, 0.1);
  backdrop-filter: blur(30px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px); */

 
  color: rgba(255, 255, 255, 0.8);
  font-size: 1em;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.dropdown-toggle-custom:hover,
.dropdown-toggle-custom:focus {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-toggle-custom::after {
  display: none !important;
}

.admin-panel {
  /* background: linear-gradient(220.55deg, #2b4255 0%, #0E2C5E 100%); */
  color: white; /* Genel metin rengi */
 
}

.conference-card-header {
  background-color: rgba(0, 0, 0, 0.2); /* Hafif koyu arka plan */
}
.conference-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 5px;
  margin-bottom: 20px;
  margin: 20px 5px 20px 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  min-width: 320px;
}

.conference-card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0 0 10px 0;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 15px;
}

.conference-details {
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 5px;
}

.session-list {
  margin-top: 20px;
}

.session-list-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.session-list-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
}
.session-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FaUserPlus {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.FaUserPlus:hover {
  color: rgba(255, 255, 255, 1);
}
/* Add these new styles */
.dropdown {
  position: static;
}

.dropdown-menu {
  position: absolute;
  right: 20px;
  top: 100%;
  z-index: 3000;
  /* background-color: rgba(255, 255, 255, 0.150);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.637); */
  border-radius: 8px;
  padding: 8px 0;
  min-width: 120px;

  
  background-color: #2b4255d2;
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(10px);

  
}

.dropdown-item {
  color: rgba(255, 255, 255, 0.8);
  padding: 8px 16px;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 1);

}

/* 
 yeniler */
 
 .add-speaker-btn {
  transition: all 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
}


.add-speaker-btn .btn-text {
  max-width: 0;
  opacity: 0;
  transition: max-width 0.3s ease, opacity 0.3s ease, margin-left 0.3s ease;
}

.add-speaker-btn:hover {
  padding-right: 15px;

 
}


.add-speaker-btn:hover .btn-text {
  max-width: 200px;
  opacity: 1;
  margin-left: 5px;
}


.session-name-container {
  width: 100%;
  max-width: 100%;
}

.session-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-size: 1.1rem;
}

.session-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}




@media (max-width: 767px) {
  .session-card-header .d-flex {
    flex-direction: row;
    align-items: stretch;
  }

  .session-actions {
    margin-top: 10px;
    justify-content: flex-start;
  }

  .session-name {
    font-size: 1rem;
    text-align: center;
  }
  .session-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Ensure it doesn't overflow on smaller screens */
  }

  .mt-4 > div > h3,
  .mt-4 > div > button {
    width: 100%;
    text-align: center;
  }
}


.session-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-align: center;
}

.fade-enter {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.fade-enter-active {
  opacity: 1;
  max-height: 1000px; /* Formunuzun maksimum yüksekliğine göre ayarlayın */
  transition: opacity 300ms, max-height 300ms ease-in;
}

.fade-exit {
  opacity: 1;
  max-height: 1000px;
}

.fade-exit-active {
  opacity: 0;
  max-height: 0;
  transition: opacity 300ms, max-height 300ms ease-in;
}

/* Mevcut CSS kodlarınızın altına ekleyin */

.modal-footer .btn-secondary:hover,
.modal-footer .btn-primary:hover {
  background-color: #f8f9fa;
  color: #000;
  border-color: #6c757d;
}

.modal-footer .btn-primary:hover {
  border-color: #0056b3;
}

.hover-visible:hover {
  background-color: #e9ecef !important;
  color: #000 !important;
  border-color: #6c757d !important;
}

/* Mevcut kodun sonuna iOS için özel stiller ekleyelim */

/* iOS için özel glassmorphism stillerini ekleyelim - sadece sorunlu bileşenler için */
@supports (-webkit-touch-callout: none) {
  /* Sadece dropdown menüler için özel stil */
  .dropdown-menu {
    background-color: rgba(43, 66, 85, 0.9); /* Daha görünür bir arka plan */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
}

/* glass-effect sınıfı için özel stiller - çok seçici */
.glass-effect .dropdown-menu {
  background-color: rgba(43, 66, 85, 0.9) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
}

/* iOS dropdown menüsü için özel stil */
.ios-dropdown {
  background-color: rgba(43, 66, 85, 0.9) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2) !important;
}

/* iOS için özel session-card stilleri */
.ios-session-card {
  background: rgba(60, 60, 80, 0.75) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
}

/* iOS için dropdown menu stilleri */
.ios-dropdown {
  background: rgba(60, 60, 80, 0.9) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
