@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap'); */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
    /* font-family: 'Open Sans', sans-serif; */
}

.L1section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: 0.5s;
  /* background: #d3cdce; */
  /* background: linear-gradient(to right, #636261, #d3cdce) no-repeat 0 0 / cover; */
}

.L1section .L1container {
  position: relative;
  width: 800px;
  height: 500px;
  background: #FFF;
  box-shadow: 0 14px 28px rgba(0, 0, 0, .2), 0 10px 10px rgba(0, 0, 0, .2);
  overflow: hidden;
  border-radius: 10px;
}

.L1section .L1container .L1user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.L1section .L1container .L1user .L1imgBox {
  position: relative;
  width: 50%;
  height: 100%;
  transition: 0.5s;
}

.L1section .L1container .L1user .L1formBox {
  position: relative;
  width: 50%;
  height: 100%;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

.L1section .L1container .L1user .L1formBox form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

.L1section .L1container .L1user .L1formBox form input {
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.L1section .L1container .L1user .L1formBox form input:focus {
  border-color: #677eff;
  box-shadow: 0 0 8px rgba(103, 126, 255, 0.5);
  background: #fff;
}

.L1rounded-button {
  width: 100%;
  padding: 10px;
  background: #677eff;
  color: #FFF;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 25px;
  transition: 0.5s;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.L1rounded-button:hover {
  background: #4755b5;
}

.L1google-login-button {
  background: #fff;
  color: #333;
  border: 1px solid #ddd;

}

.L1google-login-button:hover {
  background: #bcdef5;
}

.L1google-icon {
  margin-right: 10px;
  font-weight: bold;
  color: #4374cf;
}

.L1sign-in-separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #777;
  margin: 15px 0;
}

.L1sign-in-separator::before,
.L1sign-in-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.L1sign-in-separator span {
  padding: 0 10px;
}

.L1remember-forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.L1remember-me {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #555;
}

.L1remember-me input {
  margin-right: 5px;
}

.L1forgot-password {
  color: #677eff;
  font-size: 12px;
  text-decoration: none;
}

.L1section .L1container .L1user .L1formBox form .L1signup {
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}

.L1section .L1container .L1user .L1formBox form .L1signup a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}

.L1section .L1container .L1signupBox {
  pointer-events: none;
}

.L1section .L1container.L1active .L1signupBox {
  pointer-events: initial;
}

.L1section .L1container .L1signupBox .L1formBox {
  top: 100%;
}

.L1section .L1container.L1active .L1signupBox .L1formBox {
  top: 0;
}

.L1section .L1container .L1signupBox .L1imgBox {
  top: -100%;
  left: 0;
}

.L1section .L1container.L1active .L1signupBox .L1imgBox {
  top: 0;
}

.L1section .L1container .L1signinBox .L1formBox {
  top: 0;
}

.L1section .L1container.L1active .L1signinBox .L1formBox {
  top: 100%;
}

.L1section .L1container .L1signinBox .L1imgBox {
  top: 0;
}

.L1section .L1container.L1active .L1signinBox .L1imgBox {
  top: -100%;
}

.L1section .L1container .L1forgotPasswordBox {
  pointer-events: none;
}

.L1section .L1container.L1forgotPassword .L1forgotPasswordBox {
  pointer-events: initial;
}

.L1section .L1container .L1forgotPasswordBox .L1formBox {
  top: 100%;
}

.L1section .L1container.L1forgotPassword .L1forgotPasswordBox .L1formBox {
  top: 0;
}

.L1section .L1container .L1forgotPasswordBox .L1imgBox {
  top: -100%;
  left: 0;
}

.L1section .L1container.L1forgotPassword .L1forgotPasswordBox .L1imgBox {
  top: 0;
}

.L1section .L1container.L1forgotPassword .L1signinBox .L1formBox,
.L1section .L1container.L1forgotPassword .L1signinBox .L1imgBox {
  top: -100%;
}

@media (max-width: 991px) {
  .L1section .L1container {
    max-width: 400px;
  }
  
  .L1section .L1container .L1imgBox {
    display: none;
  }
  
  .L1section .L1container .L1user .L1formBox {
    width: 100%;
  }
}

.L1imgBox1 {
  background: #1b2dcc;
  background: linear-gradient(to right, #170acc, #3a4cf1) no-repeat 0 0 / cover;
}

.L1imgBox2 {
  background: #440657;
  background: linear-gradient(to right, #872bff, #440657) no-repeat 0 0 / cover;
}

.L1imgBox3 {
  background: #ff416c;
  background: linear-gradient(to right, #ff4b2b, #ff416c) no-repeat 0 0 / cover;
}
.L1imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.L1logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.L1logo {
  width: 80%;
  height: auto;
  max-width: 160px;
  filter: brightness(0) invert(1); /* Logoyu beyaz yap */
  transition: all 0.3s ease;
}

.L1logo:hover {
  transform: scale(1.05);
  filter: brightness(0) invert(1) drop-shadow(0 0 3px rgba(255, 255, 255, 0.7));
}

.error-message {
  color: #ff6b6b;
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  background-color: rgba(255, 107, 107, 0.1);
  position: relative;
}

.L1close-error {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #ff6b6b;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s ease;
  line-height: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.L1close-error:hover {
  opacity: 1;
  background-color: rgba(255, 107, 107, 0.2);
  transform: scale(1.1);
}

.L1verification-box {
  margin-top: 15px;
  padding: 15px;
  background-color: rgba(103, 126, 255, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(103, 126, 255, 0.3);
  transition: all 0.3s ease;
}

.L1verification-box p {
  margin-bottom: 12px;
  font-size: 14px;
  color: #555;
  text-align: center;
}

.L1verification-button {
  background: #677eff;
  margin-top: 10px;
  font-size: 13px;
  padding: 8px 15px;
}

.L1verification-button:hover {
  background: #4755b5;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(71, 85, 181, 0.3);
}

.L1logo-placeholder {
  font-size: 28px;
  font-weight: bold;
  color: #0E2C5E;
  background: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  display: inline-block;
}

/* Modal Hata ve Başarı Mesajı Stilleri */
.L1modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.L1modal-overlay.L1show {
  opacity: 1;
  visibility: visible;
}

.L1message-modal {
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  transform: translateY(-20px);
  transition: all 0.3s ease;
}

.L1error-modal {
  border-top: 5px solid #ff6b6b;
}

.L1success-modal {
  border-top: 5px solid #28a745;
}

.L1modal-overlay.L1show .L1message-modal {
  transform: translateY(0);
}

.L1modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 22px;
  color: #ff6b6b;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s;
}

.L1success-close {
  color: #28a745;
}

.L1modal-close:hover {
  opacity: 1;
  transform: scale(1.1);
}

.L1modal-header {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.L1modal-header h3 {
  color: #ff6b6b;
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}

.L1success-modal .L1modal-header h3 {
  color: #28a745;
}

.L1modal-body {
  margin-bottom: 20px;
  color: #555;
}

.L1modal-body p {
  margin: 0 0 10px 0;
  line-height: 1.5;
  font-size: 14px;
}

.L1modal-footer {
  text-align: center;
}

.L1success-button {
  background-color: #28a745 !important;
}

.L1success-button:hover {
  background-color: #218838 !important;
}

.L1verification-modal-box {
  margin-top: 15px;
  padding: 15px;
  background-color: rgba(103, 126, 255, 0.1);
  border-radius: 10px;
}

/* Yeni minimal doğrulama linki stilleri */
.L1resend-verification {
  margin-top: 20px;
  text-align: center;
}

.L1small-text {
  font-size: 13px;
  color: #666;
  margin-bottom: 0;
}

.L1resend-link {
  color: #0097B2;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.L1resend-link:hover {
  color: #006d80;
  text-decoration: underline;
}

/* Spinner (Yükleme Göstergesi) Stilleri */
.L1spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: L1spin 0.8s ease infinite;
  margin-right: 8px;
  vertical-align: middle;
}

@keyframes L1spin {
  to {
    transform: rotate(360deg);
  }
}

.L1rounded-button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.L1rounded-button span {
  display: inline-block;
  vertical-align: middle;
}