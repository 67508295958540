.custom-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1051;
}

.custom-modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 500px;
  z-index: 1052;
  animation: modal-appear 0.3s ease-out;
}

.question-edit-modal {
  max-width: 550px;
}

@keyframes modal-appear {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f8f9fa;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.custom-modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
}

.edit-icon {
  color: #007bff;
  margin-right: 10px;
  font-size: 1.1rem;
}

.custom-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #6c757d;
  cursor: pointer;
  transition: color 0.15s ease-in-out;
}

.custom-close-button:hover {
  color: #343a40;
}

.custom-modal-body {
  padding: 20px;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  color: #495057;
}

.custom-form-control {
  display: block;
  width: 100%;
  padding: 10px 12px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Select stillerini özelleştirme */
.custom-select__control {
  border-color: #ced4da !important;
  box-shadow: none !important;
  min-height: 42px !important;
}

.custom-select__control--is-focused {
  border-color: #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.custom-select__multi-value {
  background-color: #e8f4ff !important;
}

.custom-select__multi-value__label {
  color: #007bff !important;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 20px;
  border-top: 1px solid #e0e0e0;
  background-color: #f8f9fa;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.button-icon {
  margin-right: 6px;
  font-size: 0.875rem;
}

.custom-cancel-button {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: #6c757d;
  padding: 8px 16px;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  transition: all 0.2s ease;
}

.custom-cancel-button:hover {
  background-color: #e9ecef;
  border-color: #b8c2cc;
}

.custom-save-button {
  background-color: #007bff;
  border: 1px solid #007bff;
  color: #fff;
  padding: 8px 16px;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.custom-save-button:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

@media (max-width: 576px) {
  .custom-modal-content {
    width: 95%;
    margin: 0 10px;
  }
  
  .custom-modal-footer {
    flex-direction: column;
  }
  
  .custom-cancel-button, .custom-save-button {
    width: 100%;
    margin-right: 0;
    justify-content: center;
  }
  
  .custom-cancel-button {
    margin-bottom: 8px;
  }
} 