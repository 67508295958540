.admin-dashboard {
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 0;
  display: flex;
}

.admin-dashboard .row {
  width: 100%;
  margin: 0;
}

/* Sol menü */
.admin-dashboard .sidebar {
  background-color: #2c3e50;
  color: white;
  min-height: 100vh;
  padding: 20px 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 16.666667%; /* col-md-2 genişliği */
  z-index: 999;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
}

.admin-dashboard .sidebar .nav-link {
  color: #ecf0f1;
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.admin-dashboard .sidebar .nav-link:hover,
.admin-dashboard .sidebar .nav-link.active {
  background-color: #34495e;
  color: #fff;
}

.admin-dashboard .sidebar .nav-link svg {
  margin-right: 10px;
}

/* İçerik alanı */
.admin-dashboard .content-area {
  padding: 20px;
  margin-right: 16.666667%; /* sidebar genişliği kadar margin */
  width: 83.333333%; /* 100% - sidebar genişliği */
}

.admin-dashboard .content-header {
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9ecef;
}

.admin-dashboard .content-body {
  padding: 15px 0;
}

/* Kart stilleri */
.admin-dashboard .stat-card {
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.admin-dashboard .stat-card:hover {
  transform: translateY(-5px);
}

.admin-dashboard .stat-icon {
  font-size: 2.5rem;
  color: #3498db;
  opacity: 0.8;
}

/* Tablo stilleri */
.admin-dashboard .user-table {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.admin-dashboard .user-table th {
  background-color: #f8f9fa;
  border-top: none;
}

.admin-dashboard .table-actions .btn {
  padding: 0.25rem 0.5rem;
  margin-right: 5px;
}

.admin-dashboard .badge {
  padding: 0.5em 0.8em;
}

/* Form stilleri */
.admin-dashboard .add-user-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

/* Mobil düzenlemeler */
@media (max-width: 768px) {
  .admin-dashboard .sidebar {
    position: static;
    width: 100%;
    min-height: auto;
    margin-bottom: 20px;
  }
  
  .admin-dashboard .content-area {
    width: 100%;
    margin-right: 0;
  }
} 