.btn {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    font-size: 1em;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
 

 
  .btn:hover {
    background: rgba(255, 255, 255, 0.2);

   
  }

/* iOS için buton stilleri */
.ios-btn {
  background: rgba(80, 80, 80, 0.7) !important;
  color: #ffffff !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

