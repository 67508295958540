.sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  height: 100%;
  min-height: -webkit-fill-available;
  max-height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 20px 10px;
  transition: width 0.3s, transform 0.3s;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.sidebar:global(.glass-effect) {
  background-color: rgba(14, 44, 94, 0.85);
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background: transparent;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  border-radius: 5px;
  margin-bottom: 5px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.menuItem.active {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.profileAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-size: 1.2em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.profileMenu {
  border-radius: 5px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* iOS specific styles */
@supports (-webkit-touch-callout: none) {
  .sidebar {
    background-color: rgba(14, 44, 94, 0.85);
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }

  .menuItem.active {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }

  .profileAvatar {
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }

  .profileMenu {
    background-color: rgba(14, 44, 94, 0.9);
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
} 